import { TextStyle } from 'pixi.js';

import { Variables } from '../../config';

const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  miterLimit: 4,
};

const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  miterLimit: 4,
};

export const buyFeatureTitleStyle = new TextStyle({
  fontSize: 70,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 8,
  padding: 14,
  lineJoin: 'round',
});

export const buyFeatureConfirmStyle = new TextStyle({
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#ff3333',
  stroke: '#661111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  miterLimit: 4,
  padding: 5,
  lineJoin: 'round',
});

export const totalCostTextStyle = new TextStyle({
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 4,
  padding: 5,
  lineJoin: 'round',
});

export const totalCostTextAmountStyle = new TextStyle({
  fontSize: 65,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 65,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 5,
  padding: 5,
  lineJoin: 'round',
});

export const amountTextStyle = new TextStyle({
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 50,
  fill: '#ffcc48',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  miterLimit: 4,
  wordWrap: false,
  wordWrapWidth: 400,
  breakWords: true,
  padding: 5,
  lineJoin: 'round',
});

export const betValueStyle = new TextStyle({
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 50,
  fill: '#ffcc48',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  miterLimit: 4,
  lineJoin: 'round',
});

export const betValueStyleConfirm = new TextStyle({
  fontSize: 90,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 7,
  lineJoin: 'round',
});
